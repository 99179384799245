<template>
    <BanDoVue />
    <TimKiemVue :manHinh="manHinh" />
    <!--=============================fab==================================-->
    <div
        :style="`position: absolute; right: 24px; bottom: ${
            getDialogDsChuyenDi
                ? '1124'
                : getDialogChiTietChuyenDi
                ? '1030'
                : '164'
        }px; z-index: 2;`"
    >
        <div class="column btn-style">
            <DxButton
                v-if="map"
                id="fab-marker"
                class="fab rounder shadow"
                type="default"
                styling-mode="contained"
                icon="mdi mdi-map-marker-star"
                height="87px"
                width="87px"
                @click="resetNorth()"
            />
            <DxButton
                v-if="map"
                id="fab-mic"
                class="fab rounder shadow mt-3"
                type="default"
                styling-mode="contained"
                icon="mdi mdi-microphone"
                height="87px"
                width="87px"
                @click="$store.commit('trangChu/setDialogDangPhatTrien', true)"
            />
        </div>
    </div>
    <!--=============================Danh sách tuyến khi không có bản đồ==================================-->
    <DanhSachTuyenKhiBanDoLoiVue v-if="!map" />
    <!--=================================Button Hiển thị ds chuyến đi==============================-->
    <DxButton
        id="btn-expand"
        class="rounder shadow"
        type="default"
        styling-mode="contained"
        icon="mdi mdi-chevron-up"
        height="87px"
        width="87px"
        @click="setDialogDsChonDiemDen(false), setDialogDsChuyenDi(true)"
    />
    <!--=================================Copyright==============================-->
    <div
        style="
            height: 34px;
            width: 312px;
            background-color: black;
            position: absolute;
            right: 0;
            bottom: 0;
            z-index: 2;
        "
        class="text-xs-center"
    >
        <a
            href=""
            style="
                color: white;
                font-size: 20px;
                line-height: 34px;
                text-decoration: revert;
            "
            >Version 2.0.0.1©Sơn Phát C&T</a
        >
    </div>
    <!--=================================Popup danh sách chuyến đi==============================-->
    <PopupVue
        :height="manHinh == 'doc' ? '1100px' : '900px'"
        :width="manHinh == 'doc' ? '100%' : '80%'"
        :dialog="getDialogDsChuyenDi"
        title=""
        :shading="false"
        heightScrollView="calc(100% - 190px)"
        :useButton="false"
        :closeOnOutsideClick="false"
        :showCloseButton="true"
        class="border-radius-popup"
    >
        <template #close>
            <div class="btn-style">
                <DxButton
                    id="btn-close-popup"
                    type="default"
                    styling-mode="text"
                    icon="mdi mdi-chevron-down"
                    height="56px"
                    width="56px"
                    @click="setDialogDsChuyenDi(false)"
                />
            </div>
        </template>
        <template #header>
            <LocChuyenDiVue class="mt-5" />
        </template>
        <template #content>
            <DanhSachChuyenDiVue
                @trangThaiChuyenDi="
                    (check) => {
                        ParamThongBao = {
                            state: 'Error',
                            title: 'Thông báo!',
                            message_title: check.message,
                        };
                        dialogThongBao = true;
                    }
                "
            />
        </template>
    </PopupVue>
    <!--=================================Popup chi tiết chuyến đi==============================-->
    <PopupVue
        :height="manHinh == 'doc' ? '1100px' : '976px'"
        :width="manHinh == 'doc' ? '100%' : '80%'"
        :dialog="getDialogChiTietChuyenDi"
        title=""
        :shading="false"
        heightScrollView="100%"
        :useButton="false"
        :closeOnOutsideClick="false"
        :showCloseButton="true"
        classPopup="clear-padding-x-popup clear-padding-y-popup"
    >
        <template #close>
            <!-- <DxButton
                id="btn-chevron-down"
                type="default"
                styling-mode="text"
                icon="mdi mdi-chevron-down"
                height="56px"
                width="56px"
                style="position: absolute; top: 300px; z-index: 5"
            /> -->
        </template>
        <template #content>
            <DxTabPanel
                v-model:selected-index="model.tab"
                height="auto"
                :data-source="data.tabs"
                :loop="true"
                :animation-enabled="true"
                :swipe-enabled="false"
                :onTitleClick="onTitleClickTab"
                class="tabpanel-style"
            >
                <template #title="{ data: header, index: index }">
                    <div>
                        <div class="row align-center">
                            <div>
                                {{
                                    `${header.text} ${
                                        header.other
                                            ? "(" +
                                              (selected &&
                                              selected.danhGiaTrungBinh
                                                  ? selected.danhGiaTrungBinh
                                                  : 0)
                                            : ""
                                    }`
                                }}
                            </div>
                            <i
                                :class="`mdi mdi-${header.icon}`"
                                style="color: #ffff00; padding-bottom: 2px"
                            ></i>
                            <div v-if="index == 2">)</div>
                        </div>
                    </div>
                </template>
                <template #item="{ data: tab }">
                    <DxScrollView
                        id="scrollview"
                        ref="scrollViewWidget"
                        height="800px"
                        :scroll-by-content="true"
                    >
                        <div class="container-md pt-4">
                            <ThongTinXeVue v-if="tab.id == 1" />
                            <DichVuVue
                                v-if="tab.id == 2"
                                :IdXe="
                                    selected && selected.xe_Id
                                        ? selected.xe_Id
                                        : undefined
                                "
                            />
                            <XepHangVaNhanXetVue
                                v-if="tab.id == 3"
                                :IdXe="
                                    selected && selected.xe_Id
                                        ? selected.xe_Id
                                        : undefined
                                "
                            />
                            <ThongTinMoRongVue
                                v-if="tab.id == 4"
                                :IdXe="
                                    selected && selected.xe_Id
                                        ? selected.xe_Id
                                        : undefined
                                "
                            /></div
                    ></DxScrollView>
                </template>
            </DxTabPanel>
        </template>
        <template #footer>
            <div
                style="
                    padding-left: 48px;
                    padding-right: 48px;
                    padding-bottom: 34px;
                "
            >
                <div
                    class="row justify-space-between btn-style-chitiet btn-style"
                >
                    <DxButton
                        id="btn-close-popup-chitiet"
                        text="ĐÓNG"
                        type="default"
                        styling-mode="outlined"
                        height="58px"
                        width="163px"
                        @click="
                            setDialogChiTietChuyenDi(false),
                                setDialogDsChuyenDi(true)
                        "
                    />
                    <div>
                        <DxButton
                            id="btn-prev"
                            text="Trước"
                            type="default"
                            icon="mdi mdi-chevron-left"
                            height="58px"
                            class="mr-5"
                            styling-mode="text"
                            :disabled="
                                getThongTinXeDangChon &&
                                getThongTinXeDangChon.stt <= 1
                            "
                            @click="xemChuyenDiKhac('pre')"
                        />
                        <DxButton
                            id="btn-next"
                            text="Tiếp"
                            type="default"
                            :rtlEnabled="true"
                            icon="mdi mdi-chevron-right"
                            height="58px"
                            class="ml-5"
                            styling-mode="text"
                            @click="xemChuyenDiKhac('next')"
                        />
                    </div>
                    <DxButton
                        id="btn-chonxe"
                        text="Chọn xe"
                        type="normal"
                        height="58px"
                        width="163px"
                        class="secondary--bg white--text"
                        styling-mode="contained"
                        v-if="
                            $Helper.CheckRole(
                                $t('Quyen.BanVeTaiBen'),
                                $t('Quyen.BanVePosDoc')
                            )
                        "
                        @click="chonXe"
                    />
                </div>
            </div>
        </template>
    </PopupVue>
    <!-- ====================================Popup Thông báo ========================================= -->
    <PopupsVue
        :Params="{
            ShowButtonLeft: false,
            ShowButtonRight: false,
            ShowButtonClose: false,
        }"
        Width="350"
        Height="auto"
        :Model="dialogThongBao"
    >
        <template #popups
            ><NotificationVue
                :Params="ParamThongBao"
                @click="dialogThongBao = false"
                :showButtonLeft="false"
        /></template>
    </PopupsVue>
</template>
<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { DxButton, DxTextBox, DxTabPanel, DxScrollView } from "devextreme-vue";
import PopupVue from "../components/_Common/Popup";
import LocChuyenDiVue from "../components/TimKiemChuyenDi/LocChuyenDi";
import DanhSachChuyenDiVue from "../components/TimKiemChuyenDi/DanhSachChuyenDi";
import ThongTinXeVue from "../components/ChiTietChuyenDi/ThongTinXe";
import DichVuVue from "../components/ChiTietChuyenDi/DichVu";
import ThongTinMoRongVue from "../components/ChiTietChuyenDi/ThongTinMoRong";
import XepHangVaNhanXetVue from "../components/ChiTietChuyenDi/XepHangVaNhanXet";
import TimKiemVue from "../components/TimKiemChuyenDi/TimKiem";
import BanDoVue from "../components/BanDo";
import PopupsVue from "@sonphat/common-v1/components/Popups";
import DanhSachTuyenKhiBanDoLoiVue from "../components/TimKiemChuyenDi/DanhSachTuyenKhiBanDoLoi";
import NotificationVue from "@sonphat/common-v1/components/Notification";

export default {
    components: {
        DxButton,
        DxTextBox,
        PopupVue,
        LocChuyenDiVue,
        DanhSachChuyenDiVue,
        ThongTinXeVue,
        DxTabPanel,
        DichVuVue,
        ThongTinMoRongVue,
        XepHangVaNhanXetVue,
        DxScrollView,
        TimKiemVue,
        BanDoVue,
        DanhSachTuyenKhiBanDoLoiVue,
        PopupsVue,
        NotificationVue,
    },
    props: {},
    data() {
        return {
            model: { search: "", tab: 0 },
            data: {
                tabs: [
                    { id: 1, text: "Thông tin xe", icon: "", other: "" },
                    { id: 2, text: "Dịch vụ", icon: "", other: "" },
                    {
                        id: 3,
                        text: "Xếp hạng và nhận xét",
                        icon: "star",
                        other: "4.8",
                    },
                    {
                        id: 4,
                        text: "Thông tin thêm",
                        icon: "",
                        other: "",
                    },
                ],
            },
            dialogThongBao: false,
            ParamThongBao: {
                state: "Error",
                title: "Thông báo!",
                message_title: "Đã xảy ra lỗi",
                message: "Vui lòng kiểm tra lại",
            },
        };
    },
    methods: {
        ...mapActions("timKiemChuyenDi", [
            "layDanhSachLoTrinhSapXuatBen",
            "kiemTraTrangThaiChuyenDi",
        ]),
        ...mapActions("banDo", ["resetNorth"]),
        ...mapActions("chiTietChuyenDi", [
            "layThongTinXe",
            "layThongTinDichVuXe",
            "layThongTinXepHangVaNhanXetXe",
            "layThongTinThemCuaXe",
            "chuyenDiKhac",
        ]),
        ...mapMutations("soDoCho", [
            "removeDanhSachChoDaChonLocal",
            "setDanhSachChoDaChon",
        ]),
        ...mapMutations("chiTietChuyenDi", ["setTabDangChon"]),
        ...mapMutations("timKiemChuyenDi", [
            "setDialogDsChuyenDi",
            "setDialogChiTietChuyenDi",
            "setDialogDsChonDiemDen",
        ]),
        layThongTinTheoTab() {
            this.setTabDangChon(this.model.tab);
            switch (this.model.tab) {
                case 0:
                    this.layThongTinXe();
                    break;
                case 1:
                    this.layThongTinDichVuXe();
                    break;
                case 2:
                    this.layThongTinXepHangVaNhanXetXe();
                    break;
                case 3:
                    this.layThongTinThemCuaXe();
                    break;

                default:
                    break;
            }
        },
        onTitleClickTab(e) {
            this.layThongTinTheoTab();
        },
        xemChuyenDiKhac(action) {
            try {
                this.chuyenDiKhac({ action: action });
                this.layThongTinTheoTab();
            } catch (error) {
                console.log(
                    "🚀 ~ file: tim-kiem-chuyen-di.vue ~ line 351 ~ xemChuyenDiKhac ~ error",
                    error
                );
                this.$stopLoading;
            }
        },
        async chonXe() {
            this.setDanhSachChoDaChon([]);
            this.removeDanhSachChoDaChonLocal();
            if (!this.getThongTinXeDangChon) {
                return console.log("getThongTinXeDangChon null");
            }
            //Kiểm tra để mua vé
            let check = await this.kiemTraTrangThaiChuyenDi({
                idXe: this.getThongTinXeDangChon.id,
                soKhach: 0,
            });
            if (!check.data) {
                this.ParamThongBao = {
                    state: "Error",
                    title: "Thông báo!",
                    message_title: check.message,
                };
                this.dialogThongBao = true;
                return;
            }
            let query = {
                doanhNghiep_Id:
                    this.getThongTinXeDangChon.doanhNghiep_Id || null,
                xe_Id: this.getThongTinXeDangChon.xe_Id || null,
                chuyenDi_Id: this.getThongTinXeDangChon.id || null,
                nhaXe_Id: this.getThongTinXeDangChon.nhaXe_Id || null,
                xe_HangXe: this.getThongTinXeDangChon.xe_HangXe || null,
                xe_BienSoXe: this.getThongTinXeDangChon.xe_BienSoXe || null,
                soChoNgoi: this.getThongTinXeDangChon.soChoNgoi || null,
                idLoTrinh: this.getThongTinXeDangChon.loTrinh.idLoTrinh || "",
                xeHetCho: false,
                xeKhongCoSoDoCho: this.getThongTinXeDangChon.xeKhongSoDoCho,
            };
            //Xe hết chỗ
            if (
                this.getThongTinXeDangChon.soGheNgoiTrong +
                    this.getThongTinXeDangChon.soGiuongNamTrong <=
                0
            ) {
                query.xeHetCho = true;
                this.$router.push({
                    path: "thong-tin-thanh-toan",
                    query: query,
                });
                return;
            }
            //Chọn chỗ
            this.$router.push({
                path: "chon-vi-tri-cho-ngoi",
                query: query,
            });
        },
    },
    computed: {
        ...mapGetters("chiTietChuyenDi", ["getThongTinXeDangChon"]),
        ...mapGetters("banDo", ["map"]),
        ...mapGetters("timKiemChuyenDi", [
            "getDialogChiTietChuyenDi",
            "getDialogDsChuyenDi",
        ]),
        ...mapState("ManHinh", ["manHinh"]),
    },
    created() {},
    async mounted() {
        this.removeDanhSachChoDaChonLocal();
        (await this.layDanhSachLoTrinhSapXuatBen()).Store;
    },
};
</script>
<style scoped></style>
<style lang="scss" scoped>
::v-deep.btn-style .dx-button .dx-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
}
::v-deep.btn-style i {
    font-size: 40px;
}

#btn-expand {
    position: absolute;
    bottom: 24px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    background-color: white !important;
}
::v-deep#btn-expand.dx-button-has-icon .dx-icon {
    font-size: 78px !important;
    line-height: 78px;
    width: 78px;
    height: 78px;
    color: $primary;
}
#fab-marker {
    background-color: white !important;
}
#fab-mic {
    background-color: $primary !important;
}
::v-deep#fab-marker i {
    color: $primary !important;
    background-color: white !important;
}
::v-deep#fab-mic i {
    color: white !important;
    background-color: $primary !important;
}
::v-deep.chips span {
    text-transform: unset;
}
#btn-close-popup {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    top: -32px;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 1;
}
//style popup chi tiet
::v-deep.tabpanel-style .dx-tabpanel-tabs .dx-item .row > div {
    font-size: 24px;
    color: white;
}
::v-deep.tabpanel-style .dx-tabpanel-tabs .dx-item {
    height: 92px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
}
::v-deep.tabpanel-style .dx-tabpanel-tabs .dx-tabs-wrapper {
    display: flex;
    justify-content: center;
}
::v-deep.tabpanel-style .dx-tabpanel-tabs .dx-tabs-wrapper,
::v-deep.tabpanel-style .dx-tabpanel-tabs .dx-item.dx-tab-selected {
    background-color: $primary;
}
::v-deep.tabpanel-style .dx-tabpanel-tabs .dx-item.dx-state-hover {
    background-color: unset !important;
}
::v-deep.tabpanel-style .dx-tabpanel-tabs .dx-item.dx-tab-selected .row > div {
    color: $secondary;
}

::v-deep.btn-style-chitiet .dx-button span {
    font-size: 24px;
}
::v-deep.btn-style-chitiet i {
    font-size: 40px !important;
}
::v-deep
    .dx-overlay-content.dx-popup-normal.dx-popup-draggable.dx-resizable.dx-popup-fullscreen-width {
    border-radius: unset !important;
}
::v-deep#btn-chevron-down.dx-button-has-icon .dx-icon {
    width: 32px;
    height: 32px;
    font-size: 42px;
    box-shadow: $box_shadow;
}
::v-deep#btn-chevron-down::before {
    box-shadow: $box_shadow;
}
</style>
